import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SWRConfig, useSWRConfig } from 'swr'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { fetcher } from '@src/Fetch/helpers'
import Auth from '@src/Auth'
import { PAGES } from '@src/Layout/helpers'
import { useLocalStorage } from '@src/LocalStorage/helpers'
import { W_User } from '@src/types'
import {
  BackendApiContentClientUserInfo,
  BackendApiContentLocationInfo,
} from '@kjt01/greendot-wasm'
import { loadCatalog } from '@src/Lingui/helpers'
import { useTypographyContext } from '@src/Typography'
import { useNotification } from '@src/Notification/helpers'
import { useFaro } from '@src/Grafana/helpers'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { shutdown } from '@intercom/messenger-js-sdk'

interface W_UserContext {
  user: W_User
  setUser: React.Dispatch<React.SetStateAction<W_User>>
  me: BackendApiContentClientUserInfo | null
  setMe: React.Dispatch<
    React.SetStateAction<BackendApiContentClientUserInfo | null>
  >
  r_number: string | null
  setRNumber: React.Dispatch<React.SetStateAction<string | null>>
  location: BackendApiContentLocationInfo | null
  logout: () => void
  isAdmin: boolean
}

const EMPTY_USER: W_User = {
  token: null,
  token_type: null,
}

export const UserContext = createContext<W_UserContext | null>(null)

export const useUserContext = () => {
  const context = useContext(UserContext)

  if (context == null) {
    throw new Error('useUserContext must be within UserProvider')
  }

  return context
}

const User = ({ children }: { children: React.ReactNode }) => {
  const ldClient = useLDClient()

  useFaro()

  const { cache } = useSWRConfig()
  const router = useRouter()
  const { setScale } = useTypographyContext()
  const isInitialized = useRef(false)

  const [_, setNotificationVisible] = useNotification('achNotification', true)

  const [user, setUser] = useLocalStorage<W_User>({
    key: 'user',
    initialState: EMPTY_USER,
  })

  const [me, setMe] = useState<BackendApiContentClientUserInfo | null>(null)

  const [location, setLocation] =
    useState<BackendApiContentLocationInfo | null>(null)

  const [r_number, setRNumber] = useLocalStorage<string | null>({
    key: 'R_Number',
    initialState: null,
  })

  useEffect(() => {
    Sentry.setTag('r_number', r_number)

    const context = {
      kind: 'user',
      key: r_number != null ? r_number : 'anonymous-user',
      anonymous: r_number != null ? false : true,
    }

    ldClient?.identify(context, undefined, undefined)
  }, [ldClient, r_number])

  /**
   * Logout
   */
  const logout = useCallback(() => {
    setUser(EMPTY_USER)
    setMe(null)
    setNotificationVisible(true)
    shutdown()

    for (const key of cache.keys()) {
      cache.delete(key)
    }

    if ('ReactNativeWebView' in window) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'logout' }))
    }

    router.push('/')
  }, [setUser, setNotificationVisible, router, cache])

  useEffect(() => {
    if (me == null && user.token != null && !isInitialized.current) {
      isInitialized.current = true

      fetch('/api/v1/me', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        // TODO: Deprecate passing token in body, cordinate removal with backend
        body: JSON.stringify({ token: user.token }),
      })
        .then((res) => res.json())
        .then((res) => {
          if ('Err' in res) {
            throw res
          }
          return res.Ok
        })
        .then((me) => setMe(me))
        .catch(logout)
    }

    if (r_number == null && me != null) {
      const location = me.enterprises
        .flatMap((enterprise) => enterprise.locations)
        .find((location) => location.r_number != null)

      if (location != null) {
        setRNumber(location.r_number)
        setLocation(location)
      }
    }

    if (r_number != null && me != null) {
      const location = me.enterprises
        .flatMap((enterprise) => enterprise.locations)
        .find((location) => location.r_number === r_number)

      if (location == null) {
        const firstLocation = me.enterprises
          .flatMap((enterprise) => enterprise.locations)
          .find(Boolean)

        setRNumber(firstLocation?.r_number ?? null)
        setLocation(firstLocation ?? null)
      } else {
        setLocation(location)
      }
    }
  }, [logout, me, r_number, setRNumber, user.token])

  useEffect(() => {
    if (me == null) return

    loadCatalog(me.language === 'en' ? 'en' : 'zh')
    setScale(me.font_size === 'large' ? 1.5 : 1)
  }, [me, setScale])

  /**
   * Sync auth on all tabs on focus
   */
  const onVisibilityChange = useCallback(() => {
    const user = JSON.parse(localStorage.getItem(`uls:user`) || '{}')
    setUser(user)
  }, [setUser])

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [onVisibilityChange])

  const isPublicPage = PAGES.find(
    (page) => page.path === router.pathname,
  )?.isPublic

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        me,
        setMe,
        r_number,
        setRNumber,
        location,
        logout,
        isAdmin: user.token_type === 'super_user',
      }}
    >
      <SWRConfig
        value={{
          fetcher: fetcher({
            r_number,
            token: user.token,
            user_name: me?.phone_number,
          }),
          suspense: true,
        }}
      >
        {isPublicPage ? (
          children
        ) : user.token != null && r_number != null ? (
          children
        ) : (
          <Auth />
        )}
      </SWRConfig>
    </UserContext.Provider>
  )
}

export default User
